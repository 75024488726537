<template>
  <div class="bg">
    <div v-if="!loadingData && !blockScreen" class="row">
      <div class="col-md-10 col-sm-12">
        <div class="row">
          <div class="col-sm-12 col-md-12 ml-5">
            <h1 class="title gray">
              Olá {{ helper.quebrarNome(seguro.nome)[0] }}
            </h1>
            <h5 class="sub-title mt-4">
              Após uma avaliação detalhada realizada por nossa equipe de especialistas,
              que considerou fatores como marca, modelo, estado de conservação e outros critérios relevantes, ajustamos
              o valor da sua bike para refletir o preço de mercado atual.
            </h5>
            <h5 class="sub-title mt-4">
              Como resultado, o valor do seguro foi ajustado para acompanhar essa atualização. Para que possamos
              processar a devolução proporcional do valor pago inicialmente, solicitamos que você
              cadastre sua chave PIX.
            </h5>

            <div class="row mt-3 desktop">
              <div class="col-md-12 dashed">
                <span class="bold">Atenção: </span>Informe os dados corretos da sua conta bancaria ou PIX para que a
                transferência seja concluida com sucesso.
              </div>
            </div>
            <div class="d-flex dashed mobile mt-3">
              <img class="mr-3" src="/img/info-outlined.svg" />
              Após a confirmação da transferência, você receberá em seu e-mail e
              WhatsApp uma notificação.
            </div>
            <h6 class="sub-title bold mt-4">
              Por favor confirme os dados abaixo e insira os dados para realizarmos o pagamento
            </h6>

            <div class="row mb-5 mt-3">
              <div class="col-md-6">
                <div class="card-checkout">
                  <h4 class="mb-3">
                    Dados do
                    <span class="card-checkout-title bold">Segurado</span>
                  </h4>
                  <div>
                    <span class="bold">Nome completo:</span> {{ seguro.nome }}
                  </div>
                  <div><span class="bold">Email:</span> {{ seguro.email }}</div>
                  <div>
                    <span class="bold">Celular:</span> {{ formatacaoTelefone(seguro.telefone) }}
                  </div>
                  <div><span class="bold">CPF:</span> {{ helper.formatacaoCpf(seguro.cpf) }}</div>
                  <div><span class="bold">Data nascimento</span> {{
                    formatacaoData.formatarDataLocalsemUtc(seguro.nascimento) }}
                  </div>
                </div>
              </div>
              <div class="col-md-8 mt-4">
                <ValidationObserver ref="dadosBancarios">
                  <form>
                    <div class="field mb-4 ">
                      <div class="text-left mb-4">
                        Deseja receber por PIX?
                      </div>
                      <button :class="desejaPix ? 'chosen-btn' : 'choice-btn'" class="mr-5" :disabled="loading"
                        @click.prevent="
                          desejaPix = true;
                        naoDesejaPix = false;
                        ">
                        <span>Sim, manda pro PIX</span>
                      </button>
                      <button :class="naoDesejaPix ? 'chosen-btn' : 'second-choice-btn'
                        " :disabled="loading" @click.prevent="whats">
                        <span>Não tenho PIX, entrar em contato com o time</span>
                      </button>
                    </div>
                    <div v-if="desejaPix">
                      <div class="field mb-4 mt-5">
                        <ValidationProvider name="Tipo PIX" rules="required" mode="passive"
                          v-slot="{ errors, classes }">
                          <select class="select" :style="tipoPix && 'color: black;font-weight: 600;'" :class="classes"
                            v-model="tipoPix" @focus="clearDadosBancarios">
                            <option :value="undefined">Selecione o tipo da chave pix</option>
                            <option value="cpf">CPF</option>
                            <option value="cnpj">CNPJ</option>
                            <option value="email">E-mail</option>
                            <option value="phone">Telefone</option>
                            <option value="evp">Chave aleatória</option>

                          </select>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="field mb-4 mt-2">
                        <ValidationProvider name="Chave PIX" mode="passive" :rules="{
                          'required': true,
                          'email': tipoPix == 'email',
                          'cpf': tipoPix == 'cpf',
                          'cnpj': tipoPix == 'cnpj',
                          'validacao_telefone': tipoPix == 'phone',
                        }" v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <the-mask :style="{ color: chavePix ? 'black' : '' }"
                              :type="tipoPix == 'email' || tipoPix == 'evp' ? 'text' : 'tel'" class="input" :class="classes" v-model="chavePix"
                              @focus="clearDadosBancarios" :masked="true" :mask="mask" :tokens="tokens" autocomplete="nope" />
                            <label :class="chavePix && 'filled'">Chave Pix</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>

                    <div class="mt-5 mb-5">
                      <button class="continue-btn" :disabled="loading" @click.prevent="finalizar">
                        <span>Continuar </span><img src="/img/arrow-right.svg">
                      </button>
                    </div>

                  </form>
                </ValidationObserver>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading :criandoSeguro="true" v-if="loading || loadingData" />
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import config from "../config";
import formatacaoMoeda from "../helpers/formatacaoMoeda";
import formatacaoMoeda2 from "../helpers/formatacaoMoeda2";
import formatacaoMoeda3 from "../helpers/formatacaoMoeda3";
import formatacaoTelefone from "../helpers/formatacaoTelefone";
import formatacaoData from "../helpers/formatacaoData";
import helper from "../helpers/helper";
export default {
  components: {
    Loading: () => import("../components/Usuario/Loading.vue"),
  },
  data() {
    return {
      helper,
      config,
      loading: false,
      loadingData: false,
      formatacaoMoeda,
      formatacaoMoeda2,
      formatacaoMoeda3,
      formatacaoTelefone,
      formatacaoData,
      idEstorno: undefined,
      seguro: {
        nome: "",
        email: "",
        cpf: "",
        telefone: "",
        valor: 0,
      },
      tipoPix: undefined,
      chavePix: undefined,
      agencia: undefined,
      codigoBanco: undefined,
      conta: undefined,
      desejaPix: true,
      naoDesejaPix: false,
      numero: 0,
      decimal: 0,
      numeroOpicional: 0,
      decimalOpicional: 0,
      numeroTotal: 0,
      decimalTotal: 0,
      blockScreen: false,
      tokens: {
        '*': { pattern: /[0-9a-zA-Z@._+-]/ },
        '#': { pattern: /\d/ },
        'X': { pattern: /[0-9a-zA-Z]/ },
      }
    };
  },
  created() { },

  mounted() {
    this.buscarSeguro();
  },
  computed: {
    mask: function () {
      if (this.tipoPix == 'phone') return ['(##) ####-####', '(##) #####-####'];
      if (this.tipoPix == 'cpf') return '###.###.###-##';
      if (this.tipoPix == 'cnpj') return '##.###.###/####-##';
      if (this.tipoPix == 'email' || this.tipoPix == 'evp') return '*************************************************************'

      return '';
    },
  },
  watch: {},

  methods: {
    whats() {
      window.open(
        "https://api.whatsapp.com/send?phone=556135224521&text=Olá, Não tenho chave PIX para receber o valor reajustado no seguro, preciso de ajuda",
        "_blank"
      );
    },
    clearDadosBancarios() {
      this.$nextTick(() => {
        this.$refs.dadosBancarios.reset(); // Reset validation errors
      });
    },
    async buscarSeguro() {
      this.loadingData = true;

      this.idEstorno = helper.getUrlParameter("p");

      try {
        if (!this.idEstorno) return (this.loadingData = false);

        var response = await axios.get(
          `${config.API_URLV2}/EstornoSeguro/ObterInformacao?id=${this.idEstorno}`
        );

        if (response.status == 204) {
          this.loadingData = false;
          this.blockScreen = true;
          return Swal.fire({
            title: "Atenção",
            html: `<p>Não encontramos registros <b>vinculados a este link</b>, entre em contato com o nosso suporte para mais informações.</p>`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: "#28a745",
            cancelButtonText: "Fechar",
            confirmButtonText: "Entrar em contato com o suporte",
          }).then((result) => {
            if (result.value) {
              window.open(
                "https://api.whatsapp.com/send?phone=556135224521&text=Olá, entrei no link de estorno mas nada está aparecendo na tela",
                "_blank"
              );
            }
          });
        }

        this.seguro = response.data;
        this.loadingData = false;

        return true;
      } catch (error) {
        this.loadingData = false;
        await Swal.fire({
          title: "Não foi possível buscar seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return false;
      }
    },
    separarNumero(numero) {
      if (!numero) return;
      const partes = numero.toString().split(".");
      return partes[0];
    },
    separarNumeroDecimal(numero) {
      if (!numero) return;
      const partes = numero.toString().split(".");
      let valor = partes[1];
      if (valor?.length == 0 || valor == null) {
        valor = "00";
      }
      if (valor?.length == 1) {
        valor = valor.toString() + "0";
      }
      if (valor?.length > 1) {
        valor = valor.substring(0, 2);
      }

      return valor;
    },
    copy() {
      var copyText = document.getElementById("pix-copia-cola");

      copyText.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);

      this.$toast.success("Copiado com sucesso ✅");
    },

    async finalizar() {
      const valid = await this.$refs.dadosBancarios.validate();

      if (!valid) return;

      this.loading = true;
      try {

        var data = {
          id: this.idEstorno,
          tipoPix: this.tipoPix,
          chavePix: this.chavePix,
          compe: this.codigoBanco,
          agencia: this.agencia,
          conta: this.conta,
        };

        await axios.post(`${config.API_URLV2}/EstornoSeguro/finalizar`, data);

        this.loading = false;
        return this.$router.replace("/estorno-sucesso");
      } catch (error) {
        this.loading = false;
        this.mensagemErrorFinalizarSeguro(error);
      }
    },

    mensagemErrorFinalizarSeguro(error) {
      var errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.error == "ok") {
        window.location.reload(true);
      } else if (errorMessage?.startsWith("Unexpected")) {
        this.loading = false;
        Swal.fire({
          title: "Não foi possível finalizar o seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else if (this.tipoPagamento == null) {
        Swal.fire({
          title: "Não foi possível finalizar o seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else if (error?.response?.data?.error) {
        Swal.fire({
          title: "Atenção",
          html: `
  <div style="text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;">
<p>Não conseguimos validar o seu cartão. Por favor verifique os dados informados e tente novamente.</p>
<p>Abaixo os principias motivos:</p>
1 - Seu cartão está bloqueado para compras On-line.<br>
2 - Seu cartão está vencido.<br>
3 - Seu cartão está desabilitado para compras.<br>
4 - Algum dado do cartão foi digitado errado.<br>
<p class="mt-3">Caso persista, por favor entre em contato com a operadora do seu cartão e tente novamente.</p></div>`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else if (
        errorMessage ||
        (error && error.toString().toLowerCase().indexOf("cartão") !== -1)
      ) {
        this.loading = false;
        Swal.fire({
          title: "Atenção",
          text: errorMessage || error.toString(),
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        this.loading = false;

        Swal.fire({
          title: "Não foi possível finalizar o seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },

  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open%20Sans:wght@100..900&display=swap");

.waiting {
  justify-content: center;
  align-items: center;
}

.b-none {
  border: none;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #f9f9f9;
  overflow-x: hidden;
  /* overflow-y: hidden; */
  /* transition: 0.5s; */
  padding-top: 60px;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  /* transition: 0.3s; */
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 36px;
  color: #425994;
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.uppercase {
  text-transform: uppercase;
}

#beneficios-mobile .flicking-pagination-bullet-active {
  background-color: #4fa935 !important;
  width: 12px !important;
  height: 12px !important;
}

#beneficios-mobile .flicking-pagination-bullet {
  background-color: #d9d9d9;
}

.flicking-pagination-bullet-active {
  background-color: #425994 !important;
}

.w-65 {
  width: 65%;
}

.h-100 {
  height: 100px !important;
}

.nowrap {
  text-wrap: nowrap;
}

.pointer {
  cursor: pointer;
}

.confetti {
  bottom: 0px;
  position: absolute;
  width: 60%;
}

.field {
  position: relative;
}

label {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 22px;
  transition: 0.2s ease all;
  font-size: 16px;
  color: #78849e;
  font-weight: normal;
}

.filled {
  top: 2px !important;
}

input:focus~label {
  top: 2px;
  color: #4fa935;
  font-weight: bold;
}

.flicking-arrow-prev::before,
.flicking-arrow-prev::after,
.flicking-arrow-next::before,
.flicking-arrow-next::after {
  background-color: #425994 !important;
}

.flicking-arrow-next {
  right: -20px !important;
}

.flicking-arrow-prev {
  left: -20px !important;
}

.align-start {
  align-items: start;
}

.gift-text {
  color: #4fa935 !important;
}

.gift {
  list-style-image: url("/img/gift-icon-green.svg");
}

.gift-gray {
  list-style-image: url("/img/gift-icon-gray.svg");
}

.gift-white {
  list-style-image: url("/img/gift-icon-white.svg");
}

.item-benefit {
  color: #089f17 !important;
}

.chosen-item {
  color: #ffffff94 !important;
}

.card-checkout-plan {
  justify-content: space-between;
  display: flex;
}

.step-description {
  margin-top: 14px;
  text-wrap: nowrap;
  font-size: 12px;
}

.pipe {
  height: 5rem;
  border-left: 2px solid #e5e5e5;
}

.flex {
  display: flex;
}

.self-center {
  align-self: center;
}

.self-start {
  align-self: start;
}

.medium {
  padding-top: 23px;
  font-size: 1vw;
  font-weight: 800;
}

.big {
  font-size: 4rem !important;
}

.font-small {
  font-size: 0.8vw;
}

.card-optional-plan {
  display: flex;
  justify-content: space-between;
}

.space-between {
  justify-content: space-between;
}

.benefits-optional {
  padding: 10px;
}

.card-optional {
  padding: 12px;
}

.border-left-optional {
  border-left: 1px solid #e5e5e54d;
}

.optional-small {
  font-size: 24px;
  font-weight: 600;
}

.optional-price {
  display: flex;
  align-items: baseline;
  font-size: 48px;
  font-weight: 800;
  margin-top: 1rem;
}

.dark-gray {
  background-color: #78849e !important;
}

a {
  color: #089f17;
  text-decoration: underline;
  background-color: transparent;
}

.line {
  border-left: 1px solid #e5e5e5;
  height: 70px;
}

.line-optional-plan {
  border-left: 1px solid #e5e5e5;
  height: 9rem;
}

.badge-plan {
  padding: 5px 10px 5px 10px;
  font-weight: 500;
  border-radius: 8px;
  color: #425994;
  background-color: #e9e9e9;
}

.footer-plan {
  padding: 12px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #78849e;
  color: white;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-plan {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.benefit-title {
  font-size: 16px;
  font-weight: 700;
}

.benefit-content {
  font-size: 14px;
  font-weight: normal;
  font-family: Open Sans !important;
  color: #acb4be;
}

.tooltip-plan .tooltip-content {
  background-color: #2a2e43;
  color: #fff;
  text-align: left;
  padding: 36px;
  border-radius: 12px;
  position: absolute;
  z-index: 3;
  width: 130%;
}

.visible {
  visibility: visible;
}

.tooltip-content::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 65%;
  right: 30%;
  bottom: 100%;
  margin-top: -25px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent #2a2e43 transparent;
}

.recom-word {
  text-align: center;
  font-weight: bold;
  color: #4fa935;
  margin-top: 0;
  margin-bottom: 0px;
}

.end {
  text-align: end;
}

.recommended {
  border: 1px solid #4fa935;
}

.list {
  margin-top: 8px;
}

.not-included {
  text-decoration: line-through;
}

.wiped {
  color: #d1d5dc;
}

.underline {
  text-decoration: underline;
}

.price-checkout {
  align-self: center;
  justify-content: center;
  display: flex;
  font-size: 5.2vw;
  font-weight: 800;
}

.price {
  justify-content: center;
  display: flex;
  font-size: xxx-large;
  font-weight: 800;
}

.small {
  padding-top: 15px;
  font-size: small;
  font-weight: 800;
}

.card-plan-itens {
  color: #425994;
  padding-inline-start: 20px !important;
}

.white {
  color: #ffffff;
}

.align-center {
  text-align: center;
}

.flex>h6,
h4 {
  margin-bottom: 0px !important;
}

.card-plan {
  cursor: pointer;
  color: #425994;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 12px;
}

.card-checkout {
  color: #ffffff;
  padding: 24px;
  background-color: #4fa935;
  border-radius: 12px;
  min-height: 265px;
}

.chosen {
  color: #ffffff !important;
  background-color: #4fa935 !important;
}

.chosen-optional {
  color: #1e570d !important;
  background-color: #a6e894 !important;
}

.badge-secondary {
  padding: 5px 10px 5px 10px;
  border-radius: 12px;
  color: #425994;
  background-color: #e9e9e9;
}

.badge-chosen {
  padding: 5px 10px 5px 10px;
  border-radius: 12px;
  color: #425994;
  background-color: #ffffff;
}

.dashed {
  padding: 15px;
  border-radius: 12px;
  border: 2px dashed #78849e;
  background-color: #fff;
}

.dashed-optional {
  border: 1px dashed #78849e;
}

.screen {
  width: 100%;
}

.weight-600 {
  font-weight: 600;
}

.bg {
  background-image: url("/img/bike-mundo.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 30%;
  background-color: #f9f9f9;
  font-family: Inter !important;
  min-height: 100%;
}

.title {
  margin-top: 1.5rem;
  font-size: 1.5vw;
  font-family: "Inter";
  font-weight: 700;
}

.gray {
  color: #505b71;
}

.green {
  color: #4fa935 !important;
}

.bold {
  font-family: "Inter";
  font-weight: 700;
}

.sub-title {
  color: #6f6c8f;
}

.column-left {
  text-align: center;
  float: left;
  width: 10%;
}

.column-right {
  padding-left: 10px;
  float: left;
  width: 90%;
  color: #78849e;
  font-size: 16px;
  font-weight: normal;
}

.step-by-step:after {
  content: "";
  display: table;
  clear: both;
}

.sub-input {
  color: #6f6c8f;
  font-size: 12px;
}

.input {
  font-size: 16px;
  display: block;
  width: 100%;
  height: 64px;
  border: none;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 16px 0px 16px;
  border-radius: 12px;
  background: #fff;
}

.input-pix {
  font-size: 16px;
  display: block;
  width: 100%;
  height: 64px;
  border: 1px solid #425994;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px;
  border-radius: 12px;
  background: #fff;
}

.selected {
  color: black;
  font-weight: 600;
}

.select {
  display: block;
  width: 100%;
  height: 64px;
  border: none;
  padding: 0px 16px;
  border-radius: 12px;
  background: #fff;
  font-size: 16px;
  color: #78849e;
  font-weight: normal;
}

.input::placeholder {
  color: #78849e;
  font-size: 14px;
  font-weight: normal;
}

.confirm-pay {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #4fa935;
  border-radius: 8px;
  background: #4fa935;
  color: #fff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.continue-btn {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #4fa935;
  border-radius: 8px;
  background: #4fa935;
  color: #fff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.back-btn {
  border: 1px solid transparent;
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  background: #caced9;
  color: #505b71;
}

.continue-btn:disabled,
button[disabled] {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #d1d5dc;
  border-radius: 8px;
  background: #d1d5dc;
  color: #ffffff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.choice-btn {
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #ffffff;
  color: #089f17;
}

.optional-ghost {
  text-align: center;
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #ffffff;
  color: #089f17;
  text-wrap: nowrap;
}

.second-choice-btn {
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #ffffff;
  color: #8a92a0;
}

.ghost {
  text-align: center;
  padding: 10px 15px 10px 15px;
  border: 1px solid #8a92a0;
  border-radius: 8px;
  background: #ffffff;
  color: #8a92a0;
  text-wrap: nowrap;
}

.radius {
  border-radius: 12px !important;
}

.chosen-btn {
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #4fa935;
  color: #ffffff;
}

.steps {
  margin-left: 5%;
  left: 44%;
  right: 57%;
}

.center {
  display: flex;
  align-items: center;
}

.left-side {
  background-color: #fff;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
}

.row {
  margin: 0;
}

*:focus {
  outline: 0;
}

input:focus::placeholder {
  color: transparent;
}

.invalid-feedback {
  font-size: 14px;
}

@media (max-width: 768px) {
  .continue-btn {
    padding: 7px !important;
    text-wrap: nowrap !important;
    font-weight: bold !important;
    font-size: 11px !important;
  }

  .m-justify-center {
    justify-content: center;
  }

  .font-small {
    font-size: 12px;
  }

  .bg {
    background-image: none !important;
  }

  .w-105 {
    width: 105%;
  }

  .w-100 {
    width: 100%;
  }

  .benefit-content {
    padding-bottom: 40px;
  }

  .tooltip-plan .tooltip-content {
    background-color: #2a2e43;
    color: #fff;
    text-align: left;
    padding: 24px 0 10px 0 !important;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
  }

  .card-checkout-title {
    font-size: 5.7vw;
  }

  .card-checkout {
    margin-top: 20px;
    color: #ffffff;
    padding: 24px;
    background-color: #4fa935;
    border-radius: 12px;
    min-height: 0;
  }

  .medium {
    padding-top: 12px;
    font-size: 4.5vw;
    font-weight: 500;
  }

  .big {
    font-size: 50px !important;
  }

  .card-checkout-plan {
    display: block;
  }

  .justify-center {
    justify-content: center;
  }

  .pipe {
    border-left: 0 !important;
    height: 0 !important;
    border-bottom: 2px solid #e5e5e5;
  }

  .card-plan {
    padding-top: 10px !important;
  }

  .border-left-optional {
    border-left: 0 !important;
  }

  .optional-price {
    margin-top: 0 !important;
  }

  .card-optional {
    padding: 0 !important;
    width: 100%;
  }

  .grid {
    display: grid;
  }

  .mr-5 {
    margin-right: 15px !important;
  }

  .sub-title {
    font-size: 16px;
  }

  .desktop {
    display: none !important;
  }

  .title {
    margin-top: 0;
    font-size: 23px;
  }

  .left-side {
    display: none;
  }

  .ml-5,
  .mx-5 {
    margin-left: 0 !important;
  }
}

@media (min-width: 769px) {
  .mobile {
    display: none !important;
  }
}
</style>
